
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import cx from 'classnames';
import s from './CollectionSymbol.module.scss';
const propTypes = {
    symbol: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    className: PropTypes.string
};
const CollectionSymbol = ({ symbol = '', height = 300, width = 300, className = null }) => {
    return (<div className={cx(s.root, className)}>
      <div className={s.inner}>
        {symbol && <Image src={`${symbol}?w=300`} height={height * (300 / width)} width={300}/>}
      </div>
    </div>);
};
CollectionSymbol.propTypes = propTypes;
export default CollectionSymbol;

    async function __Next_Translate__getStaticProps__194cc7978a3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionSymbol/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc7978a3__ as getStaticProps }
  